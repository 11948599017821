<template>
  <div class="feed-body">
    <div class="feed-contents">
      <v-row style="width: 100%">
        <v-col v-for="agency in agencies" :key="agency._id" cols="6">
          <volunteer-agency-card :agency="agency" />
        </v-col>
      </v-row>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div
        v-else-if="agencies.length == 0"
        class="d-flex flex-column align-center justify-center white--text"
      >
        <div class="mb-3 mt-15">
          <v-img
            :src="require('@/assets/svg/empty-post.svg')"
            style="width: 60px; height: 50px"
            contain
          />
        </div>
        No agencies found
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VolunteerAgencyCard from "./AgencyCard.vue";
export default {
  components: { VolunteerAgencyCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions("agency", {
      fetchAllAgencies: "fetchAllAgencies",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreAgencies() {
      this.loadingMore = true;
      this.loadMore({ skip: this.agencies.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    getCompletedInfo(agency) {
      var str = "";
      if (!agency.image) str += "Logo";
      if (!agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!agency.contact_person || !agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!agency.address) str ? (str += ", Address") : (str = "Address");
      if (!agency.city) str ? (str += ", City") : (str = "City");
      if (!agency.state) str ? (str += ", State") : (str = "State");
      if (!agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
    goToProfile(id) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id },
      });
    },
  },
  computed: {
    ...mapGetters("agency", {
      agencies: "getAllAgencies",
    }),
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllAgencies()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
